import React from 'react';

export default function CircleProgress({ size, maxSize, circleSize = 6, strokeWidth = 3 }) {
  const percentage = size * 100 / maxSize;
  const radius = 10;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (size / maxSize) * circumference;
  return (
    <div className={`relative w-${circleSize} h-${circleSize}`}>
      <svg className="w-full h-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="18"
          cy="18"
          r={radius}
          fill="none"
          className="stroke-current text-gray-200 dark:text-neutral-700"
          strokeWidth={strokeWidth}
        ></circle>
        <g className="origin-center -rotate-90 transform">
          <circle
            cx="18"
            cy="18"
            r={radius}
            fill="none"
            className={`stroke-current ${percentage < 70 ? "text-green-500" : `${percentage < 90 ? "text-primary" : "text-red-500"}`} `}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          ></circle>
        </g>
      </svg>
    </div>
  );
}
