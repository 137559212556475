import React from "react";
import CircleProgress from "@/components/elements/CircleProgress";

const Input =({
    label = <></>,
    maxSize = undefined,
    value = "",
    onChange = () => { },
    styles = {
        parent: "",
        input: "",
        title: ""
    },
    disabled = false,
    resizable = false,
    showRemainCharactersCount = true,
    errorBorder = false,
    ...props
}) => {
    function onChangeInput(e) {
        if (!maxSize || (e.target.value?.length ?? 0) <= maxSize) {
            onChange(e.target.value);
        }
    }

    return <div className={`flex flex-col gap-[6px] ${styles.parent}`}>
        <div className={`flex justify-between items-end ${styles.title}`}>
            {label}
            {
                showRemainCharactersCount && maxSize
                    ?
                    <div className={`flex items-center gap-0.5 text-sm ${(value?.length ?? 0) == maxSize ? 'text-red-500' : ''}`}>
                        <span>{value?.length ?? 0}</span>
                        <span>/</span>
                        <span>{maxSize}</span>
                        <CircleProgress size={value?.length ?? 0} maxSize={maxSize}/>
                    </div>
                    :
                    <></>
            }
        </div>
        <input
            {...props}
            disabled={disabled}
            value={value}
            onChange={onChangeInput}
            className={` ${disabled ? 'text-mutedforeground cursor-not-allowed' : 'text-foreground cursor-pointer'} rounded-[12px] focus:outline-none focus-visible:ring-0 focus:border-foreground focus:ring-offset-0 focus:ring-0 border-[1px] ${errorBorder ? 'border-red-500' : 'border-inputsBorder'}  h-[40px] ${styles.input} ${!resizable ? " resize-none " : ""}`}
        />
    </div>

}
const MemoizedInput  =  React.memo(Input, (prevState, newState) => prevState.disabled == newState.disabled && prevState.value === newState.value && prevState.errorBorder == newState.errorBorder);
// export {input};
export {MemoizedInput as Input};
